'use strict';

export default {
    methods: {
        sendGAEvent(name, data = '') {
            if (!this.$gtm) {
                return;
            }
            this.$gtm.push({
                event: name,
                data
            });
        },
        sendGADataLayer(data) {
            if (!this.$gtm) {
                return;
            }
            this.$gtm.push(data);
        },
    }
};
