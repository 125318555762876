import { debounce, once } from 'lodash-es';
import ga from '~/mixins/ga';

export default {
    mixins: [ga],
    data() {
        return {
            reportScrollStart: once(this._reportScrollStart),
            reportScrollHalf: once(this._reportScrollHalf),
            reportScrollEnd: once(this._reportScrollEnd),
        };
    },
    methods: {
        doHorizontalScrollTracking: debounce(function(e) {
            const scrollLeft = e.target.scrollLeft;
            const width = e.target.scrollWidth;
            const width2 = e.target.getBoundingClientRect().width;
            const scrollableWidth = width - width2;

            const percentage = (scrollLeft / scrollableWidth) * 100;

            switch (true) {
            case percentage > 90:
                return this.reportScrollEnd(percentage);
            case percentage > 50:
                return this.reportScrollHalf(percentage);
            case percentage > 5:
                return this.reportScrollStart(percentage);
            }
        }, 100),
        _reportScrollStart(percentage) {
            this.sendGAEvent('slider:scroll:start', {
                percentage
            });
        },
        _reportScrollHalf(percentage) {
            this.sendGAEvent('slider:scroll:half', {
                percentage
            });
        },
        _reportScrollEnd(percentage) {
            this.sendGAEvent('slider:scroll:end', {
                percentage
            });
        },
    }
};
