<template>
    <div
        v-if="!supportsTouch"
        ref="container"
        v-dragscroll="dragScrollEnabled"
        class="drag-to-scroll drag-to-scroll__wrapper"
        :data-snap="snap"
        :class="(dragging) ? 'is-dragging' : ''"
        @dragscrollstart="handleDragStart()"
        @dragscrollend="handleDragEnd()"
        @scroll="doHorizontalScrollTracking"
    >
        <div class="drag-to-scroll__padding" />
        <slot />
        <div class="drag-to-scroll__padding" />
    </div>
    <div
        v-else
        ref="container"
        class="drag-to-scroll__wrapper"
        :data-snap="snap"
        @scroll="doHorizontalScrollTracking"
    >
        <div class="drag-to-scroll__padding" />
        <slot />
        <div class="drag-to-scroll__padding" />
    </div>
</template>

<script>
'use strict';

import horizontalScrollTracking from '~/mixins/horizontalScrollTracking';

export default {
    mixins: [horizontalScrollTracking],

    props: {
        snap: {
            type: Boolean,
            default: true
        },
        scrollStart: {
            type: [Number, String],
            default: null
        }
    },

    data() {
        return {
            dragging: false,
            supportsTouch: false,
            dragScrollEnabled: false
        };
    },

    mounted() {
        if ('ontouchstart' in window || navigator.msMaxTouchPoints) {
            this.supportsTouch = true;
        } else {
            this.dragScrollEnabled = true;
        }

        if (this.scrollStart) {
            if (this.scrollStart === 'center') {
                const center = this.$refs.container.scrollWidth / 2;
                this.$refs.container.scrollTo(center, 0);
            } else {
                this.$refs.container.scrollTo(this.scrollStart, 0);
            }
        }
    },

    methods: {
        handleDragStart() {
            this.dragging = true;
        },
        handleDragEnd() {
            this.dragging = false;
        }
    }
};
</script>

<style lang="less" src="./DragToScroll.less"></style>
